import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { TIMEOUT } from '~/constants';
import { QUIZ_API_URL } from '~/constants/config';

export interface IQuizApi {
  getQuickPoll: (quickPollId: number) => Promise<AxiosResponse>;
  getQuickPollStatistics: (quickPollId: number) => Promise<AxiosResponse>;
  setQuickPollAnswerId: (quickPollAnswerId: number) => Promise<AxiosResponse>;
  setQuickPollViewed: (quickPollId: number) => Promise<AxiosResponse>;
}

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
}

export default ({ $axios, $logger, $sentry }: Params): IQuizApi => {
  const quizRequest = (baseURL: string, timeout: number = TIMEOUT): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL,
      timeout,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
      },
    }) as NuxtAxiosInstance;

    axiosInstance.onError($sentry.captureException);

    attachLoggerToAxios(axiosInstance, $logger, 'quiz');

    return axiosInstance;
  };

  return {
    getQuickPoll: (quickPollId: number) => {
      const url = `api/quickpolls/${quickPollId}`;
      return quizRequest(QUIZ_API_URL).get(url);
    },
    setQuickPollAnswerId: (quickPollAnswerId: number) => {
      const url = `api/stats/answer/click/${quickPollAnswerId}`;
      return quizRequest(QUIZ_API_URL).post(url);
    },
    getQuickPollStatistics: (quickPollId: number) => {
      const url = `api/stats/quickpolls/full/${quickPollId}`;
      return quizRequest(QUIZ_API_URL).get(url);
    },
    setQuickPollViewed: (quickPollId: number) => {
      const url = `api/stats/quickpolls/view/${quickPollId}`;
      return quizRequest(QUIZ_API_URL).post(url);
    },
  };
};
