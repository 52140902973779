import { Context } from 'vm';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { AxiosResponse } from 'axios';
import { Route } from 'vue-router';
import { stringify } from 'qs';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { TIMEOUT } from '~/constants';
import { WORDPRESS_API_URL } from '~/constants/config';
import { getUrl, getApiEndpointDependingOnRoute, getParamsByRoute } from '~/utils/apiUtils';
import { IAdsFormPayload } from '~/types';
import { formatToOldApiInterceptor } from '~/utils/content/formatToOldApi';

export const URL_PREFIX_IRON_MAIDEN = 'api/lh-iron-maiden/v1';

const PATHS = {
  HOME_POSTS: '/home-posts',
  AUTHOR: '/author',
  TOPICS: '/category',
  TAGS: '/tag',
  STREAMS: '/streams',
  TOP: '/top',
  POSTS_BY_DATE: '/posts-by-date',
  SINGLE_POST: '/single-post',
  PREVIEW: '/preview',
  SEO: '/seo',
  SCRIPTS: '/scripts',
  MENUS: '/menus',
  SIDEBARS: '/sidebars',
  LOGGED_IN: '/logged_in',
  CONTACT_FORM: '/ads-form',
  SEARCH: '/aggregate-search',
} as const;

export interface IronMaidenApi {
  fetchArticles: (route: Route, page: number, limit?: number) => Promise<AxiosResponse>;
  fetchSinglePost: (slug: Route['path']) => Promise<AxiosResponse>;
  fetchByTag: (slug: Route['path']) => Promise<AxiosResponse>;
  fetchPreviewSinglePost: (
    articleId: number,
    previewType: 'admin-post-preview' | 'public-post-preview',
    previewTypeHash: string
  ) => Promise<AxiosResponse>;
  fetchLastNArticles: (limit: number) => Promise<AxiosResponse>;
  sendContactForm: (payload: IAdsFormPayload) => Promise<AxiosResponse>;
  search: (payload: string[]) => Promise<AxiosResponse<{ items: Array<any> }>>;
}

type Params = {
  $axios: Context['$axios'];
  $logger: Context['$logger'];
  $sentry: Context['$sentry'];
  version: string;
};

export default ({ $axios, $logger, version }: Params): IronMaidenApi => {
  const request = (): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: `${WORDPRESS_API_URL}/${URL_PREFIX_IRON_MAIDEN}`,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    attachLoggerToAxios(axiosInstance, $logger, 'iron-maiden');

    axiosInstance.onError(() => {});

    axiosInstance.interceptors.response.use(
      formatToOldApiInterceptor,
      (errorResponse) => {
        return Promise.reject(errorResponse);
      },
    );

    return axiosInstance;
  };

  const defaultParams = version.length ? { version } : {};

  const getParams = (params: any = {}) => ({ ...defaultParams, ...params });

  return {
    fetchArticles: (route, page, limit = 30) => {
      const url = getApiEndpointDependingOnRoute(route, PATHS);

      const params = getParams({
        page,
        limit,
        ...getParamsByRoute(route),
      });

      return request().get(url, { params });
    },

    fetchSinglePost: (slug) => {
      const url = getUrl(PATHS.SINGLE_POST, slug);

      return request().get(url);
    },

    fetchByTag: (slug) => {
      const url = getUrl(PATHS.TAGS, slug);

      return request().get(url);
    },

    fetchPreviewSinglePost: (articleId, previewType = 'admin-post-preview', previewTypeHash) => {
      const url = getUrl(PATHS.PREVIEW, String(articleId));
      return request().get(url, {
        params: getParams({
          preview_type: previewType,
          preview_type_hash: previewTypeHash,
        }),
      });
    },

    fetchLastNArticles: (limit) => {
      const url = getUrl(PATHS.HOME_POSTS);
      return request().get(url, {
        params: { page: 1, limit },
      });
    },

    sendContactForm: (payload: IAdsFormPayload) => {
      const url = getUrl(PATHS.CONTACT_FORM);
      return request().post(url, stringify(payload));
    },

    search: (payload: string[]) => {
      const url = getUrl(PATHS.SEARCH);
      return request().post(url, { 'slug-list': payload }, {
        headers: { 'Content-Type': 'application/json' },
      });
    },
  };
};
