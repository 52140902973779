// Подробнее см https://youtrack.lifehacker.ru/issue/LH-564
import {
  ActionTree,
  MutationTree,
  GetterTree,
} from 'vuex';

import {
  SEND_AB_TITLE_EVENTS,
  ADD_AB_TITLE_DATA,
  ADD_AB_TITLE_EVENT,
  REMOVE_AB_TITLE_EVENTS,
  GET_AB_TITLE_BY_ARTICLE,
  SET_TRUE_ADDED_UNLOAD_HANDLER,
  REMOVE_AB_TITLES,
} from '~/constants';

export const namespaced = false;

type IState = {
  titles: {
    [propName: string]: string
  },
  events: Array<AbTitleEvent>,
  addedUnloadHandler: boolean
};

export const state = (): IState => ({
  titles: {},
  events: [],
  addedUnloadHandler: false,
});

export const getters: GetterTree<IState, IRootState> = {
  [GET_AB_TITLE_BY_ARTICLE]: (state: IState) => (id: number | string): string | null => {
    return state.titles[String(id)] || null;
  },
};

export const actions: ActionTree<IState, IRootState> = {
  [SEND_AB_TITLE_EVENTS] ({ state, commit }) {
    if (state.events.length) {
      return this.$wordpressApi.sendAbTitleEvents(state.events)
        .then(() => {
          commit(REMOVE_AB_TITLE_EVENTS);
        });
    }
  },

  [ADD_AB_TITLE_EVENT] ({ state, commit, dispatch }, payload: AbTitleEvent) {
    if (!state.addedUnloadHandler) {
      window.addEventListener('beforeunload', () => {
        dispatch(SEND_AB_TITLE_EVENTS);
      });
      commit(SET_TRUE_ADDED_UNLOAD_HANDLER);
    }

    return commit(ADD_AB_TITLE_EVENT, payload);
  },
};

export const mutations: MutationTree<IState> = {
  [ADD_AB_TITLE_DATA] (state, { id, title }: { id: number, title: string }) {
    state.titles[String(id)] = title;
  },

  [REMOVE_AB_TITLES] (state) {
    state.titles = {};
  },

  [ADD_AB_TITLE_EVENT] (state, payload: AbTitleEvent) {
    state.events.push(Object.freeze(payload));
  },

  [REMOVE_AB_TITLE_EVENTS] (state) {
    state.events = [];
  },

  [SET_TRUE_ADDED_UNLOAD_HANDLER] (state) {
    state.addedUnloadHandler = true;
  },
};
