import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { FULL_SEARCH_API_URL, WORDPRESS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';

export interface ISearchApi {
  getSearchItems: (search: string, type: string, page: number, limit: number) => Promise<AxiosResponse>;
  getSearchItemsByIds: (includes: Array<number>) => Promise<AxiosResponse>;
};

function getBaseUrl (api: string) {
  switch (api) {
    case 'wp':
      return WORDPRESS_API_URL;
    case 'search':
      return FULL_SEARCH_API_URL;
    default:
      return FULL_SEARCH_API_URL;
  }
}

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
}
export default ({ $axios, $sentry, $logger }: Params): ISearchApi => {
  const searchRequest = (api: string): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: getBaseUrl(api),
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    axiosInstance.onError($sentry.captureException);

    attachLoggerToAxios(axiosInstance, $logger, 'search');
    return axiosInstance;
  };

  return {
    getSearchItems: (search, type, page, limit = 15) => {
      const url = '/api/20/v1/search/';
      const params = { page, limit, search } as any;

      if (type) {
        params.post_type = type;
      }

      return searchRequest('wp').get(url, { params });
    },

    getSearchItemsByIds: (includes: Array<number>) => {
      const url = '/fullsearch/solr/postId';
      const params = {
        includes: includes.join(),
        page: 1,
        limit: includes.length,
      } as any;

      return searchRequest('search').get(url, { params });
    },
  };
};
